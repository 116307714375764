import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main/main.component';
import { DashboardComponent } from './main/dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { TradingCalculatorComponent } from './main/trading-calculator/trading-calculator.component';
import { AngularFireAuthGuard, hasCustomClaim, redirectUnauthorizedTo, redirectLoggedInTo } from '@angular/fire/auth-guard';
import { CanActivateViaAuthGuard } from './can-activate-via-auth.guard';
import { TradingInvestComponent } from './main/trading-invest/trading-invest.component';
import { TradingSapComponent } from './main/trading-sap/trading-sap.component';


const routes: Routes = [
  { path: "", redirectTo: "/login", pathMatch: "full" },
  { path: "login", component: LoginComponent, pathMatch: "full" },
  {
    path: "main",
    component: MainComponent,
    canActivate: [CanActivateViaAuthGuard],
    children: [
      { path: "", redirectTo: "/main/home", pathMatch: "full" },
      { path: "home", component: DashboardComponent, pathMatch: "full" },
      { path: "tradingcalculator", component: TradingCalculatorComponent, pathMatch: "full" },
      { path: "tradinginvest", component: TradingInvestComponent, pathMatch: "full" },
      { path: "tradingsap", component: TradingSapComponent, pathMatch: "full" }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
