import { Component, OnInit, Inject, forwardRef } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import 'firebase/firestore';
import { Invest } from 'src/model/invest';
import { MainComponent } from '../main.component';
import { AngularFireAuth } from '@angular/fire/auth';
import { ChartType, ChartDataSets, ChartOptions } from 'chart.js';
import { MultiDataSet, Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip, Color } from 'ng2-charts';

@Component({
  selector: 'app-trading-invest',
  templateUrl: './trading-invest.component.html',
  styleUrls: ['./trading-invest.component.scss']
})
export class TradingInvestComponent implements OnInit {

  nowNav = 0.00

  nowAmount = 0

  moneyOriginal = 0

  // START Chart การเติบโต %
  public EvolutionAveragelineChartData: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' }
  ];
  public EvolutionAveragelinelineChartLabels: Label[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  public EvolutionAveragelinelineChartColors: Color[] = [
    {
      backgroundColor: "rgba(32, 255, 69, 0.137)",
      borderColor: 'green',
    }
  ];
  public EvolutionAveragelinelineChartLegend = true;
  public EvolutionAveragelinelineChartType = 'line';
  public EvolutionAveragelinelineChartPlugins = [];
  // END Chart การเติบโต %

  // START Chart มูลค่า
  public barChartOptions: ChartOptions = {
    responsive: true,
  };
  public barChartLabels: Label[] = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];
  public barChartLabelsNow: Label[] = ['Now'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];

  public barChartData: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
    { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' }
  ];

  public barChartDataNow: ChartDataSets[] = [
    { data: [1000000], label: 'Series A' },
    { data: [1000000], label: 'Series B' }
  ];
  // END Chart มูลค่า

  doughnutChartLabels: Label[] = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];
  doughnutChartData: MultiDataSet = [[10, 10, 10, 10, 10, 10, 10, 10, 20]]
  doughnutChartType: ChartType = 'doughnut';

  public lineChartData: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
    { data: [85, 29, 40, 81, 56, 55, 40], label: 'Series B' },
  ];
  public lineChartLabels: Label[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  public lineChartColors: Color[] = [
    {
      borderColor: 'orange',
    },
    {
      borderColor: 'green',
    },
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [];


  model = {
    id: "",
    title: "",
    input: {
      date: "",
      price: "",
      amount: "",
      dca: "1000"
    },
    status: {
      box_detail: false
    },
    main: [],
    detail: []
  }



  constructor(
    @Inject(forwardRef(() => MainComponent)) private appMain: MainComponent,
    public auth: AngularFireAuth,
    private afs: AngularFirestore
  ) { }

  ngOnInit() {
    this.onLoadInvestList()
  }

  onLoadInvestList() {
    this.appMain.model.isLoading = true
    this.afs.collection('invest', ref => ref.where('user', '==', this.auth.auth.currentUser.uid)).valueChanges().subscribe(dao => {
      this.appMain.model.isLoading = false
      console.log(dao)
      this.model.main = dao
      this.doughnutChartLabels = []
      this.doughnutChartData = []
      var bucketAllPrice = 0.00
      // set chart donut
      this.model.main.forEach(item => {
        this.doughnutChartLabels.push(item["name"])
        bucketAllPrice += parseFloat(item["money_original"])
      })

      var bucketItemAverage = []
      this.model.main.forEach(item => {
        var current: number = (parseFloat(item["money_original"]) / bucketAllPrice) * 100
        bucketItemAverage.push(current)
      })
      this.doughnutChartData = [bucketItemAverage]
      console.log(this.doughnutChartData)
      console.log(this.doughnutChartLabels)
    })

  }

  handleSelectInvestToSeeDetail(id, title) {
    this.appMain.model.isLoading = true
    this.model.title = title
    this.model.id = id
    this.afs
      .collection("invest_detail", ref => ref.where('id', '==', id).orderBy("amount_all"))
      .valueChanges()
      .subscribe(dao => {
        this.appMain.model.isLoading = false
        this.model.status.box_detail = true
        console.log(dao)
        this.nowNav = dao[dao.length-1]["price"]
        this.model.detail = dao

        var buyNav = []
        var buyNavAvg = []
        var originalMoney = []
        var newMoney = []
        var evolutionAvg = []
        var lastOriginal = []
        var lastCurrent = []
        this.lineChartLabels = []
        this.EvolutionAveragelinelineChartLabels = []
        this.barChartLabels = []
        this.model.detail.forEach(item => {
          buyNav.push(item["price"])
          originalMoney.push(item["money_original"])
          this.nowAmount = item["amount_all"]
          this.moneyOriginal = item["money_original"]

          newMoney.push(item["value"])
          evolutionAvg.push(item["evolution_persent"])
          buyNavAvg.push(item["money_original"] / item["amount_all"])
          var d = new Date(item["date"]["seconds"] * 1000)
          this.lineChartLabels.push((d.getDate()) + "-" + (d.getMonth() + 1) + "-" + d.getFullYear())
          this.EvolutionAveragelinelineChartLabels.push((d.getDate()) + "-" + (d.getMonth() + 1) + "-" + d.getFullYear())
          this.barChartLabels.push((d.getDate()) + "-" + (d.getMonth() + 1) + "-" + d.getFullYear())
        })
        this.EvolutionAveragelineChartData = [{
          data: evolutionAvg,
          label: "การเติบโต %"
        }]
        this.lineChartData = []
        this.barChartData = []

        this.barChartDataNow = []

        // lastOriginal
        this.barChartDataNow.push({
          data: lastOriginal,
          label: "ราคาที่จากซื้อตลาด/หน่วย"
        })

        this.barChartDataNow.push({
          data: lastCurrent,
          label: "ราคาปัจจุบัน"
        })

        this.lineChartData.push({
          data: buyNav,
          label: "ราคาที่จากซื้อตลาด/หน่วย"
        })
        this.lineChartData.push({
          data: buyNavAvg,
          label: "ราคาที่ซื้อได้จริงเฉลี่ยงวดอื่น/หน่วย"
        })
        this.barChartData.push({
          data: originalMoney,
          label: "เงินต้น"
        })
        this.barChartData.push({
          data: newMoney,
          label: "มูลค่าปัจจุบัน"
        })
      })
  }

  handleChangePrice(){
    console.log("handleChangePrice")
  }

  handleNew() {
    var remarks = prompt("Do you want to create invest", "Name")
    if (remarks == null) {
      console.log("No value");
    } else {
      var idNew = Math.random() + ""
      this.afs.doc("invest/" + idNew).set({
        "id": idNew,
        "name": remarks,
        "money_now": 0,
        "money_original": 0,
        "user": this.auth.auth.currentUser.uid
      })
    }
  }

  handleAddDetail() {
    if (
      this.model.input.amount == "" ||
      this.model.input.date == "" ||
      this.model.input.dca == "" ||
      this.model.input.price == ""
    ) {
      alert("Please input value")
      return
    }

    var last = 0
    var amount_all = parseFloat(this.model.input.amount)
    var money_original = parseFloat(this.model.input.dca)
    var value = parseFloat(this.model.input.dca)
    var evoVar = 0
    var evoPer = 0

    if (this.model.detail.length != 0) {
      last = this.model.detail[this.model.detail.length - 1]
      amount_all = parseFloat(last["amount_all"]) + parseFloat(this.model.input.amount)
      money_original = last["money_original"] + parseFloat(this.model.input.dca)
      value = amount_all * parseFloat(this.model.input.price)
      evoVar = value - money_original
      evoPer = (evoVar / (money_original / 100))
    }

    this.afs.collection("invest_detail").add({
      "amount": parseFloat(this.model.input.amount),
      "amount_all": amount_all,
      "date": new Date(this.model.input.date),
      "dca": parseFloat(this.model.input.dca),
      "evolution_persent": evoPer,
      "evolution_value": evoVar,
      "money_original": money_original,
      "id": this.model.id,
      "price": parseFloat(this.model.input.price),
      "value": value
    })

    this.afs.doc("invest/" + this.model.id).update({
      "id": this.model.id,
      "money_now": value,
      "money_original": money_original
    }).then(dao => {
      console.log(dao)
    })
  }

  priceChange() {
    if (this.model.input.amount == "") {
      var prices = parseFloat(this.model.input.price)
      var dca = parseFloat(this.model.input.dca)
      var am = dca / prices
      this.model.input.amount = "" + am
    }
  }
}
