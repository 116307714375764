import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth, storage } from 'firebase/app';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  model = {
    username: localStorage["username"],
    password: ""
  }

  constructor(
    public auth: AngularFireAuth,
    public router: Router
    ) { }

  checkLogin(){
    this.auth.auth.onAuthStateChanged(user => {
      if(user != null) {
        this.router.navigate(["main/home"])
      }
    })
  }

  ngOnInit() {
    this.checkLogin()
  }

  handleLogin() {
    localStorage["username"] = this.model.username
    this.auth.auth.signInWithEmailAndPassword(this.model.username, this.model.password).catch(error => {
      alert("Login Failed")
    }).then(success => {
      if(success != undefined){
        this.router.navigate(["main/"])
      }
    })
  }

}
