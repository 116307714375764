import { Component, OnInit, Inject, forwardRef } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MainComponent } from '../main.component';
import { AngularFireAuth } from '@angular/fire/auth';
import { ChartType, ChartDataSets, ChartOptions } from 'chart.js';
import { MultiDataSet, Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip, Color } from 'ng2-charts';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  invest = {
    price: {
      now: 0,
      original: 0
    }

  }

  doughnutChartLabels: Label[] = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];
  doughnutChartData: MultiDataSet = [[10, 10, 10, 10, 10, 10, 10, 10, 20]]
  doughnutChartType: ChartType = 'doughnut';

  constructor(
    @Inject(forwardRef(() => MainComponent)) private appMain: MainComponent,
    public auth: AngularFireAuth,
    private afs: AngularFirestore
  ) { }

  ngOnInit() {
    this.onLoadInvestList()
  }

  onLoadInvestList() {
    this.appMain.model.isLoading = true
    this.afs.collection('invest', ref => ref.where('user', '==', this.auth.auth.currentUser.uid)).valueChanges().subscribe(dao => {
      this.appMain.model.isLoading = false
      console.log(dao)
      dao.forEach(item => {
        this.invest.price.now += item["money_now"]
        this.invest.price.original += item["money_original"]
      })
      this.doughnutChartLabels = []
      this.doughnutChartData = []
      var bucketAllPrice = 0.00

      // set chart donut
      dao.forEach(item => {
        this.doughnutChartLabels.push(item["name"])
        bucketAllPrice += parseFloat(item["money_original"])
      })

      var bucketItemAverage = []
      dao.forEach(item => {
        var current: number = (parseFloat(item["money_original"])/bucketAllPrice) * 100
        bucketItemAverage.push(current)
      })
      this.doughnutChartData = [bucketItemAverage]
      console.log(this.doughnutChartData)
      console.log(this.doughnutChartLabels)
    })

  }

}
