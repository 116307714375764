import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trading-calculator',
  templateUrl: './trading-calculator.component.html',
  styleUrls: ['./trading-calculator.component.scss']
})
export class TradingCalculatorComponent implements OnInit {

  model = {
    input: "",
    result: {
      profit1: 0,
      profit2: 0,
      profit3: 0,
      profit4: 0,
      profit5: 0,
      lose1: 0,
      lose2: 0,
      lose3: 0,
      lose4: 0,
      lose5: 0,
    }
  }
  constructor() {
    this.model.input = localStorage["tradingPrice"]
    this.handleCalculator()
   }

  ngOnInit() {
  }

  handleCalculator(){
    localStorage["tradingPrice"] = this.model.input
    var value = parseFloat(this.model.input)
    this.model.result = {
      profit1: ((value/100)*1)+value,
      profit2: ((value/100)*2)+value,
      profit3: ((value/100)*3)+value,
      profit4: ((value/100)*4)+value,
      profit5: ((value/100)*5)+value,
      lose1: ((value/100)*1)-value,
      lose2: ((value/100)*2)-value,
      lose3: ((value/100)*3)-value,
      lose4: ((value/100)*4)-value,
      lose5: ((value/100)*5)-value,
    }
  }

}
