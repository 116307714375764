import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth, storage } from 'firebase/app';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  model = { isLoading: false }

  constructor(
    public auth: AngularFireAuth,
    public router: Router
  ) { }

  ngOnInit() {
  }

  handleLogout() {
    this.auth.auth.signOut()
  }

}
