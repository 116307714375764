import { Component, OnInit, Inject, forwardRef } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import 'firebase/firestore';
import { Invest } from 'src/model/invest';
import { MainComponent } from '../main.component';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-trading-sap',
  templateUrl: './trading-sap.component.html',
  styleUrls: ['./trading-sap.component.scss']
})
export class TradingSapComponent implements OnInit {

  model = {
    list: [],
    table: [],
    list_select: null,
    input: {
      name: "",
      price: "",
      amount: "",
      value: "",
      isSell: false,
    },
    input_table: {
      sell_amount: "",
      sell_price: "",
      sell_price_all: "",
      buy_amount: "",
      buy_price: "",
      buy_price_all: ""
    },
    sts: false
  }
  constructor(
    @Inject(forwardRef(() => MainComponent)) private appMain: MainComponent,
    public auth: AngularFireAuth,
    private afs: AngularFirestore
  ) {
    this.onLoadSAPList()
  }

  ngOnInit() {
  }

  onLoadSAPList() {
    this.appMain.model.isLoading = true
    this.afs.collection('sap', ref => ref.where('user', '==', this.auth.auth.currentUser.uid)).valueChanges().subscribe(dao => {
      this.appMain.model.isLoading = false
      console.log(dao)
      this.model.list = dao
    })
  }

  handleSeeDetail(id, select) {
    this.appMain.model.isLoading = true
    this.model.table = [];
    this.model.sts = true
    this.model.list_select = select
    this.model.input.name = this.model.list_select.name
    this.model.input.price = this.model.list_select.price_original
    this.model.input.amount = this.model.list_select.amount
    this.model.input.value = this.model.list_select.value_original
    this.model.input_table.buy_amount = this.model.input.amount
    this.model.input_table.sell_amount = this.model.input.amount

    this.afs
      .collection("sap_detail", ref => ref.where('id', '==', id).orderBy("time"))
      .valueChanges()
      .subscribe(dao => {
        this.appMain.model.isLoading = false
        console.log(dao)
        if (dao.length == 0) { this.model.input.isSell = true; return }
        if (dao[dao.length - 1]["buy_amount"] == 0) {
          this.model.input.isSell = false;
        } else {
          this.model.input.isSell = true;
        }
        this.model.table = dao
      })
  }

  handleAdd() {
    this.appMain.model.isLoading = true
    this.model.sts = false
    var id = Math.random() + "";
    if (this.model.input.isSell) {
      // new row
      this.afs.doc("sap_detail/" + id).set({
        "sell_amount": parseFloat(this.model.input_table.sell_amount),
        "sell_price": parseFloat(this.model.input_table.sell_price),
        "sell_price_all": parseFloat(this.model.input_table.sell_price_all),
        "buy_amount": 0,
        "buy_price": 0,
        "buy_price_all": 0,
        "fee": parseFloat(this.model.input_table.sell_price_all) - (parseFloat(this.model.input_table.sell_amount) * parseFloat(this.model.input_table.sell_price)),
        "money_cashback": 0,
        "id": this.model.list_select["id"],
        "id_sapdetail": id,
        "time_sell": new Date(),
        "time_buy": new Date(),
        "time": new Date(),
      }).then(sts => {
        this.appMain.model.isLoading = false
        console.log(sts)
      })
    } else {
      // edit row
      var am = parseFloat(this.model.input_table.buy_amount)
      var p = parseFloat(this.model.input_table.buy_price)
      var pAll = parseFloat(this.model.input_table.buy_price_all)
      var last = this.model.table[this.model.table.length - 1]
      var feeSell = parseFloat(last["sell_price_all"]) - (parseFloat(last["sell_amount"]) * parseFloat(last["sell_price"]))
      var feeBuy = pAll - (am * p)
      var cashback = parseFloat(last["sell_price_all"]) - pAll

      this.afs.doc("sap_detail/" + this.model.table[this.model.table.length - 1]["id_sapdetail"]).update({
        "buy_amount": am,
        "buy_price": p,
        "buy_price_all": pAll,
        "fee": feeSell + feeBuy,
        "money_cashback": cashback,
        "time_buy": new Date(),
      }).then(() => {
        this.appMain.model.isLoading = false
      })
    }

    // update card summary
    // เงินทอน
    var cbOld = parseFloat(this.model.list_select["money_cashback"])
    if (isNaN(cbOld)) {
      cbOld = 0
    }
    if (isNaN(cashback)) {
      cashback = 0
    }
    var cbNew = cbOld + cashback

    // ดอยใหม่ (ทุนเก่า - เงินทอน) / จำนวนหุ้น
    var pNow = (parseFloat(this.model.list_select["value_original"]) - cbNew) / parseFloat(this.model.list_select["amount"])
    if (isNaN(pNow)) {
      pNow = 0
    }

    // ต้นทุนใหม่
    var vn = pNow * parseFloat(this.model.list_select["amount"])
    if (isNaN(vn)) {
      vn = 0
    }

    this.afs.doc("sap/" + this.model.list_select["id"]).update({
      "price_now": pNow,
      "money_cashback": cbNew,
      "value_now": vn
    })

  }

  createNew() {
    this.appMain.model.isLoading = true
    this.model.sts = false
    var id = Math.random() + "";
    this.afs.doc("sap/" + id).set({
      "id": id,
      "amount": 0,
      "money_cashback": 0,
      "name": "new",
      "price_now": 0,
      "price_original": 0,
      "value_now": 0,
      "value_original": 0,
      "user": this.auth.auth.currentUser.uid
    }).then(() => {

      this.appMain.model.isLoading = false
    })
  }

  handleSaveCard() {
    this.model.sts = false
    this.appMain.model.isLoading = true
    this.afs.doc("sap/" + this.model.list_select["id"]).update({
      "name": this.model.input.name,
      "price_original": this.model.input.price,
      "amount": this.model.input.amount,
      "value_original": this.model.input.value,
      "value_now": this.model.input.value,
      "price_now": this.model.input.price
    }).then(() => {

      this.appMain.model.isLoading = false
    })
  }

  updatePrice(type) {
    if (type == "sell") {
      this.model.input_table.sell_price_all = "" + (parseFloat(this.model.input_table.sell_amount) * parseFloat(this.model.input_table.sell_price))
    } else {
      this.model.input_table.buy_price_all = "" + (parseFloat(this.model.input_table.buy_amount) * parseFloat(this.model.input_table.buy_price))
    }
  }

  handleDeleted() {
    if (confirm("Do you want to delete ?")) {
      this.afs.doc("sap/" + this.model.list_select["id"]).delete().then(() => {
        this.model.sts = false
      })

    }
  }
}
